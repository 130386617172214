import { retrieveToken } from 'utils/Auth/AuthProvider'

import instance from './instance'

export const createRequestProvider = () => {
  const getRequest = async (url, params, headers, responseType) => {
    const token = await retrieveToken()

    return instance.request({
      headers: {
        Authorization: token ? `Bearer ${token.access}` : '',
        'Accept-Language': 'es',
        ...headers
      },
      method: 'get',
      url: url,
      params: {
        ...params
      },
      responseType
    })
  }

  const postRequest = async (url, data, headers) => {
    return instance.request({
      headers: {
        'Accept-Language': 'es',
        ...headers
      },
      method: 'post',
      url: url,
      data: data
    })
  }

  const postAuthRequest = async (url, data, headers) => {
    const token = await retrieveToken()
    return instance.request({
      headers: {
        Authorization: token ? `Bearer ${token.access}` : '',
        'Accept-Language': 'es',
        ...headers
      },
      method: 'post',
      url: url,
      data: data
    })
  }

  const postFileRequest = async (url, data) => {
    return instance.request({
      method: 'post',
      url: url,
      data: data
    })
  }

  const postRequestBlob = async (url, data) => {
    return instance.request({
      method: 'post',
      url,
      data,
      responseType: 'blob'
    })
  }

  const deleteAuthRequest = async (url, data, headers) => {
    const token = await retrieveToken()
    return instance.request({
      headers: {
        Authorization: token ? `Bearer ${token.access}` : '',
        'Accept-Language': 'es',
        ...headers
      },
      method: 'delete',
      url: url,
      data: data
    })
  }

  const operationsPostAuthRequest = async (url, data, headers) => {
    const token = await retrieveToken()
    return instance.request({
      headers: {
        Authorization: token ? `Bearer ${token.access}` : '',
        'Accept-Language': 'es',
        ...headers
      },
      method: 'post',
      url: url,
      data: data,
      timeout: 600000 // Set timeout to 600 seconds
    })
  }

  const operationsPostBlobRequest = async (url, data, headers) => {
    const token = await retrieveToken()
    return instance.request({
      headers: {
        Authorization: token ? `Bearer ${token.access}` : '',
        'Accept-Language': 'es',
        ...headers
      },
      method: 'post',
      url: url,
      data: data,
      responseType: 'blob',
      timeout: 300000 // Set timeout to 300 seconds
    })
  }

  return {
    getRequest,
    postRequest,
    postAuthRequest,
    postFileRequest,
    postRequestBlob,
    deleteAuthRequest,
    operationsPostAuthRequest,
    operationsPostBlobRequest
  }
}

export const {
  getRequest,
  postRequest,
  postAuthRequest,
  postFileRequest,
  postRequestBlob,
  deleteAuthRequest,
  operationsPostAuthRequest,
  operationsPostBlobRequest
} = createRequestProvider()
