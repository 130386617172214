import { useContext, useReducer } from 'react'

import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import NavBar from 'components/DashboardPage/Header/NavBar'
import SideBar from 'components/DashboardPage/Header/SideBar'
import HeaderContext from 'context/headerContext'
import HeaderReducer, { initialState } from 'reducers/headerReducer'
import HeaderRouter from 'routers/HeaderRouter'
import OperationsNavBar from './OperationsNavBar'

import AuthContext from 'context/authContext'
import { FlagsProvider } from 'context/flagsContext'
import SidebarContextWrapper from './SidebarContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.spacing(0, 1),
    width: '100%',
    ...theme.mixins.toolbar
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
    overflowX: 'hidden',
    marginTop: '80px',
    [theme.breakpoints.only('md')]: {
      marginTop: '140px'
    }
  },
  content: {
    position: 'relative',
    width: '100%'
  }
}))

export default function Header() {
  const classes = useStyles()

  const [headerState, headerDispatch] = useReducer(HeaderReducer, initialState)
  const { userDetails } = useContext(AuthContext)

  const NavBarComponent =
    userDetails?.role != 'Operations' ? NavBar : OperationsNavBar

  return (
    <FlagsProvider>
      <SidebarContextWrapper>
        <HeaderContext.Provider value={{ headerState, headerDispatch }}>
          <div className={classes.root}>
            <NavBarComponent
              headerState={headerState}
              headerDispatch={headerDispatch}
            />
            <SideBar />
            <Container maxWidth="lg">
              <main className={classes.rightSide}>
                <div className={classes.content}>
                  <HeaderRouter />
                </div>
              </main>
            </Container>
          </div>
        </HeaderContext.Provider>
      </SidebarContextWrapper>
    </FlagsProvider>
  )
}
