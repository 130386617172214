import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { PlaidExchangeToken, PlaidLinkToken } from 'axios/requests/integrations'
import { useCallback, useEffect, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  },
  button: {
    margin: theme.spacing(1)
  }
}))

const PlaidIntegration = () => {
  const classes = useStyles()
  const [linkToken, setLinkToken] = useState('')
  const [loading, setLoading] = useState(false)

  const onSuccess = useCallback(async (public_token) => {
    try {
      await PlaidExchangeToken({ public_token })
    } catch (error) {
      console.error('Error exchanging public token:', error)
    } finally {
      setLoading(false)
    }
  }, [])

  const onExit = useCallback(() => {
    setLoading(false)
  }, [])

  const fetchLinkToken = async () => {
    setLoading(true)
    try {
      const response = await PlaidLinkToken({})
      setLinkToken(response.data.link_token)
    } catch (error) {
      console.error('Error creating link token:', error)
      setLoading(false)
    }
  }

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit
  })

  useEffect(() => {
    if (linkToken && ready) {
      open()
    }
  }, [linkToken, ready, open])

  return (
    <div className={classes.contentContainer}>
      <div className={classes.reportContainer}>
        <Card className={classes.reportCard}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography className={classes.cardReportName}>
              Plaid Integration
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={fetchLinkToken}
              >
                Connect a bank account
              </Button>
            )}
          </Grid>
        </Card>
      </div>
    </div>
  )
}

export default PlaidIntegration
