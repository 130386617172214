import { Divider } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

import { bigNumberFormatter } from 'utils/formatters/currencyFormatters'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

const useStyles = makeStyles((theme) => ({
  subCardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginLeft: 0,
      marginRight: 0
    }
  },
  subCard: {
    flex: 1,
    flexWrap: 'wrap',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  subCardTitle: {
    marginRight: 'auto',
    marginBottom: theme.spacing(2)
  },
  subCardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: theme.spacing(1),
    padding: '0 !important'
  },
  subCardBottomDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%'
  },
  firstElement: {
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  secondElement: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& $subContentTitle': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'auto',
        marginRight: 0
      }
    }
  },
  subContentTitle: {
    marginRight: 'auto'
  },
  subContentNumber: {
    display: 'flex',
    justifyContent: 'start',
    fontWeight: '400',
    fontSize: '1.3rem',
    marginBottom: theme.spacing(1),
    fontVariantNumeric: 'proportional-nums',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  returnTypo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '400',
    fontSize: '1.3rem',
    marginBottom: theme.spacing(1),
    fontVariantNumeric: 'proportional-nums',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  divider: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  errorColor: {
    color: theme.palette.error.main
  }
}))

export default function BalanceSummary({ balanceData }) {
  const classes = useStyles()

  return (
    <CardWrapper title="Resumen" background shadow={false}>
      {balanceData ? (
        <div className={classes.subCardsContainer}>
          <Card className={classes.subCard}>
            <CardContent className={classes.subCardContent}>
              <div className={classes.subCardBottomDiv}>
                <div className={classes.firstElement}>
                  <Typography
                    variant="body1"
                    className={clsx(classes.returnTypo, {
                      [classes.errorColor]: balanceData.total_return < 0
                    })}
                  >
                    {bigNumberFormatter(balanceData.total_return)}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.subContentTitle}
                  >
                    Retorno
                  </Typography>
                </div>
                <div className={classes.secondElement}>
                  <Typography
                    variant="body1"
                    className={clsx(classes.subContentNumber, {
                      [classes.errorColor]: balanceData.balance < 0
                    })}
                  >
                    {bigNumberFormatter(balanceData.balance)}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.subContentTitle}
                  >
                    Patrimonio
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
          <Card className={classes.subCard}>
            <CardContent className={classes.subCardContent}>
              <div className={classes.subCardBottomDiv}>
                <div className={classes.firstElement}>
                  <Typography
                    variant="body1"
                    className={clsx(classes.subContentNumber, {
                      [classes.errorColor]: balanceData.withdrawal_sum < 0
                    })}
                  >
                    {bigNumberFormatter(balanceData.withdrawal_sum)}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.subContentTitle}
                  >
                    Retiros
                  </Typography>
                </div>
                <div className={classes.secondElement}>
                  <Typography
                    variant="body1"
                    className={clsx(classes.subContentNumber, {
                      [classes.errorColor]: balanceData.deposit_sum < 0
                    })}
                  >
                    {bigNumberFormatter(balanceData.deposit_sum)}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.subContentTitle}
                  >
                    Depósitos
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <Loading />
      )}
    </CardWrapper>
  )
}
