import { useContext, useEffect, useMemo, useState } from 'react'

import { format } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { Fade, Paper, useTheme } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

import HeaderContext from 'context/headerContext'
import useResponsive from 'hooks/useResponsive'
import Auxiliary from 'utils/Auxiliary/Auxiliary'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { assetBenchmarkSeriesRequest } from 'axios/requests/portfolio'

const useStyles = makeStyles(() => ({
  formControlContainer: {
    zIndex: 1100,
    backgroundColor: '#fff',
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none'
    }
  },
  formControl: {
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px'
  }
}))

export default function AssetBenchmarkSeries() {
  const classes = useStyles()
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [graphData, setGraphData] = useState([])
  const [assetClass, setAssetClass] = useState('ALL')
  const [filterOptions, setFilterOptions] = useState([])
  const theme = useTheme()
  const { isDesktop } = useResponsive()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let mounted = true
    setLoading(true)
    const fetchData = async () => {
      try {
        const result = await assetBenchmarkSeriesRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
          portfolio_id: currentPortfolio.id,
          asset_class: assetClass
        })
        return result.data
      } catch (err) {
        console.log(err)
      }
    }

    if (mounted && currentPortfolio && currency) {
      fetchData().then((data) => {
        if (mounted && data?.summaryAssetsData) {
          setGraphData(data.summaryAssetsData.children)
          setLoading(false)
          if (filterOptions.length === 0) {
            const filteredAssets = data.summaryAssetsData.children.filter(
              (asset) => asset.total_return_series.length > 0
            )
            const newOptions = filteredAssets.map((asset) => {
              return {
                code: asset.asset_class.code,
                name: asset.asset_class.name
              }
            })
            newOptions.splice(0, 0, { code: 'ALL', name: 'Ninguno' })
            setFilterOptions(newOptions)
          }
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [currency, currentPortfolio, startDate, endDate, assetClass])

  const getChartOptions = (asset) => {
    const portfolioSeries = []
    for (let i = 0; i < asset.total_return_series.length; i++) {
      const seriesDatum = asset.total_return_series[i]
      portfolioSeries.push([Date.parse(seriesDatum[0]), seriesDatum[1]])
    }

    const benchmarkSeries = []
    if (asset.benchmark_total_return_series) {
      for (let i = 0; i < asset.benchmark_total_return_series.length; i++) {
        const seriesDatum = asset.benchmark_total_return_series[i]
        benchmarkSeries.push([Date.parse(seriesDatum[0]), seriesDatum[1]])
      }
    }

    return {
      credits: false,
      plotOptions: {
        line: {
          marker: {
            enabled: false
          },
          events: {
            legendItemClick: function () {
              return false
            }
          }
        }
      },
      chart: {
        zoomType: 'x',
        style: {
          fontFamily: 'GaretBook'
        }
      },
      exporting: {
        chartOptions: {
          // specific options for the exported image
          plotOptions: {
            series: {
              dataLabels: {
                enabled: false
              }
            }
          }
        },
        fallbackToExportServer: false,
        filename: asset.asset_class.name
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
        labels: {
          style: {
            color: theme.palette.text.primary
          }
        }
      },
      yAxis: {
        title: {
          text: null
        },
        plotLines: [
          {
            value: 0,
            width: 1,
            color: theme.palette.text.primary
          }
        ],
        labels: {
          style: {
            color: theme.palette.text.primary
          }
        }
      },
      tooltip: {
        shared: true,
        valueDecimals: 2
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        itemStyle: {
          fontFamily: 'GaretBook',
          fontSize: 14,
          fontWeight: 'normal'
        }
      },
      series: [
        {
          name: asset.asset_class.name,
          data: portfolioSeries,
          turboThreshold: 0,
          color: theme.palette.primary.main
        },
        {
          name: 'Benchmark',
          data: benchmarkSeries,
          turboThreshold: 0,
          showInLegend: asset.benchmark_total_return_series?.length > 0,
          color: theme.palette.info.main
        }
      ]
    }
  }

  const chartOptions = useMemo(() => {
    if (graphData.length <= 0) return {}
    const options = {}
    graphData
      .filter((asset) => asset.total_return_series.length > 0)
      .forEach((asset, index) => {
        options[index] = getChartOptions(asset)
      })
    return options
  }, [graphData])

  return (
    <CardWrapper
      title="Retornos clases de activo"
      titleFeedback="Retornos clases de activo"
      action={
        filterOptions.length > 0 && (
          <FormControl component={Paper} elevation={0} variant="filled">
            <InputLabel id="select-filled-filter-label">Filtro</InputLabel>
            <Select
              labelId="select-filled-filter-label"
              id="filter-options"
              value={assetClass}
              classes={{ root: classes.formControl }}
              style={{ minWidth: 230 }}
              onChange={(event) => {
                setAssetClass(event.target.value)
              }}
            >
              {filterOptions.map((option, index) => {
                return (
                  <MenuItem value={option.code} key={index}>
                    {option.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )
      }
    >
      <Auxiliary>
        {loading ? (
          <Loading extraHeight={150} />
        ) : (
          <Grid style={{ marginTop: 20 }} container spacing={3}>
            {Object.keys(chartOptions).map((_, index) => (
              <Fade key={index} in timeout={{ enter: 1000 }}>
                <Grid key={index} item xs={isDesktop ? 6 : 12}>
                  <CardWrapper>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={chartOptions[index]}
                    />
                  </CardWrapper>
                </Grid>
              </Fade>
            ))}
          </Grid>
        )}
      </Auxiliary>
    </CardWrapper>
  )
}
