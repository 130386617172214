import { useContext, useEffect, useState } from 'react'

import { format } from 'date-fns'

import Grid from '@material-ui/core/Grid'
import HeaderContext from 'context/headerContext'
import { bigNumberFormatter } from 'utils/formatters/currencyFormatters'
import Loading from 'utils/UI/Loading'
import DefaultTable from 'utils/UI/Tables/DefaultTable'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import {
  DepositsWithdrawlsRequest,
  DownloadDepositsWithdrawlsRequest
} from 'axios/requests/movements'
import { errorNotification } from 'utils/UI/Notifications/Notifications'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import GetAppIcon from '@material-ui/icons/GetApp'

const colsData = [
  {
    id: 'date',
    label: 'Fecha'
  },
  {
    id: 'type',
    label: 'Tipo'
  },
  {
    id: 'account',
    label: 'Cuenta'
  },
  {
    id: 'currency',
    label: 'Moneda'
  },
  {
    id: 'amount',
    label: 'Monto',
    align: 'right',
    minWidth: 100,
    format: (value) => bigNumberFormatter(value)
  }
]

export default function DepositsWithdrawalsTable() {
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currentPortfolio } = headerState
  const [rowsData, setRowsData] = useState(null)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let didCancel = false
    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await DepositsWithdrawlsRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          portfolio_id: currentPortfolio.id
        })
        if (!didCancel) {
          setRowsData(result.data)
          setLoading(false)
        }
      } catch (err) {
        errorNotification('generalError')
      }
    }

    if (currentPortfolio) {
      fetchData()
    }

    return () => {
      didCancel = true
    }
  }, [currentPortfolio, startDate, endDate])

  const downloadHandler = async () => {
    setLoadingDownload(true)
    try {
      const response = await DownloadDepositsWithdrawlsRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        portfolio_id: currentPortfolio.id
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Abaqus - Aportes y Rescates.xlsx') // or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingDownload(false)
  }

  return (
    <CardWrapper title="Aportes y Rescates">
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Tooltip title={rowsData?.length ? 'Descargar' : ''}>
            {loadingDownload ? (
              <CircularProgress />
            ) : (
              <IconButton
                disabled={!rowsData?.length}
                onClick={downloadHandler}
                color="primary"
              >
                <GetAppIcon />
              </IconButton>
            )}
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          {loading || !rowsData ? (
            <Loading />
          ) : (
            <DefaultTable colsData={colsData} rowsData={rowsData} />
          )}
        </Grid>
      </Grid>
    </CardWrapper>
  )
}
