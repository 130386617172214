import {
  getRequest,
  operationsPostAuthRequest,
  operationsPostBlobRequest,
  postAuthRequest
} from 'axios/request'

export function HoldingsRequest(params) {
  const url = '/operations/holding-list/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function ClientsRequest(params) {
  const url = '/operations/client-list/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function AssetRequest(params) {
  const url = '/operations/asset-list/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function AccountsRequest(params) {
  const url = '/operations/account-list/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function AssetSearchRequest(params) {
  const url = '/operations/asset-search/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DownloadHoldingsRequest(params) {
  const url = '/operations/download-holdings/'
  return operationsPostBlobRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function DataSourceHealthRequest() {
  const url = '/operations/datasource-health/'
  return getRequest(url, {}, {})
}

export function DuplicateDividendsRequest() {
  const url = '/operations/duplicate-dividends/'
  return getRequest(url, {}, {})
}

export function ValidateDividendsRequest(params) {
  const url = '/operations/validate-dividends/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DuplicateSplitsRequest() {
  const url = '/operations/duplicate-splits/'
  return getRequest(url, {}, {})
}

export function ValidateSplitsRequest(params) {
  const url = '/operations/validate-splits/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DeleteDividendsRequest(params) {
  const url = '/operations/delete-dividends/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function AllocationsReportRequest(params) {
  const url = '/operations/allocations-report/'
  return operationsPostBlobRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function ClientPortfoliosRequest(params) {
  const url = '/operations/client-portfolios/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function PortfolioDetail(params) {
  const url = '/operations/portfolio-data/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function ClientPortfolioHoldingsRequest(params) {
  const url = '/operations/portfolio-holdings/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DownloadPortfolioSeries(params) {
  const url = '/operations/porftolio-series/'
  return getRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function UnbalancedDividendsHoldingsResquest(params) {
  const url = '/assets/unbalanced-dividends/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function ListDisabledDividendsRequest(params) {
  const url = '/operations/list-inactive-dividends/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function ListValidatedDividendsRequest(params) {
  const url = '/operations/list-validated-dividends/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function RecoverDividendsRequest(params) {
  const url = '/operations/recover-dividends/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function InvalidateDividendsRequest(params) {
  const url = '/operations/invalidate-dividends/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DisableDividendsRequest(params) {
  const url = '/operations/disable-dividends/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function CreateHoldingAdjustmentRequest(params) {
  const url = '/operations/create-holding-adjustments/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function GetAssetsRequest(params) {
  const url = '/operations/asset/list/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function GetAssetCompositionRequest(params) {
  const url = '/operations/asset-composition/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function UpdateAssetCompositionRequest(params) {
  const url = '/operations/asset-composition/update/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function GetAssetClassesCodesRequest(params) {
  const url = '/operations/asset-classes-codes/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function UpdateAssetRequest(params) {
  const url = '/operations/asset/update/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function CreateAssetRequest(params) {
  const url = '/operations/asset/create/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function GetInstitutionsRequest(params) {
  const url = '/operations/institution/list/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function getDistinctValuesForAssetsFieldsRequest(params) {
  const url = '/operations/assets/distinct-values/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function UserPortfoliosOrderRequest(params) {
  const url = '/operations/user-portfolios-order/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function TransactionsRequest(params) {
  const url = '/operations/transactions-list/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DownloadTransactionsRequest(params) {
  const url = '/operations/download-transactions/'
  return operationsPostBlobRequest(
    url,
    {
      ...params
    },
    {},
    'blob'
  )
}

export function CommandExecuteRequest(params) {
  const url = '/operations/command-execute/'
  return operationsPostAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function USDAndUFHealthRequest(params) {
  const url = '/operations/usd-health/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function DataSourceLogsRequest(params) {
  const url = '/operations/datasource-logs/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}
