import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import 'index.css'
import 'react-toastify/dist/ReactToastify.min.css'

import AppRouter from 'routers/AppRouter'
import { theme } from 'utils/UI/Theme'

import * as serviceWorker from 'serviceWorker'

ReactDOM.render(
  <>
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
    />
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  </>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
