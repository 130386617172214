import { getRequest, postAuthRequest } from 'axios/request'

export function PlaidLinkToken(params) {
  const url = '/integrations/plaid/link-token/'
  return getRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function PlaidExchangeToken(params) {
  const url = '/integrations/plaid/exchange-public-token/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}

export function PlaidGetAccounts(params) {
  const url = '/integrations/plaid/get-accounts/'
  return postAuthRequest(
    url,
    {
      ...params
    },
    {}
  )
}
