import { useState } from 'react'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { login } from 'utils/Auth/AuthProvider'
import {
  errorNotification,
  successNotification
} from 'utils/UI/Notifications/Notifications'

import { getTokensRequest, sendEmailPasswordReset } from 'axios/requests/auth'

const useStyles = makeStyles((theme) => ({
  loginForm: {
    height: '100%',
    width: '100%',
    marginTop: theme.spacing(4),
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '80%'
    }
  },
  InputWrapper: {
    width: '100%',
    maxWidth: 400,
    marginBottom: theme.spacing(3)
  },
  loginHelpers: {
    width: '100%',
    maxWidth: 400,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(3)}px 0`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  rememberMeCheckbox: {
    height: 42,
    position: 'absolute',
    left: -25,
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      width: 'max-content'
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 14
    }
  },
  forgotPasswordLink: {
    width: '100%',
    height: 42,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: '#707070',
    right: -40,
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      width: 'max-content'
    },
    // right: 30,
    '& button': {
      fontSize: 14,
      textTransform: 'none'
    }
  },
  btnSubmit: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },
    '&:active': {
      outline: 0
    }
  }
}))

export default function LoginForm() {
  /* We call the style declared on top */
  const classes = useStyles()
  const rememberedEmail = window.localStorage.getItem('REMEMBERED_USER_EMAIL')

  const [rememberEmail, setRememberEmail] = useState(!!rememberedEmail)

  const [email, setEmail] = useState(rememberedEmail || '')
  const [password, setPassword] = useState('')

  const [open, setOpen] = useState(false)
  const [resetEmail, setResetEmail] = useState('')

  function validateForm() {
    if (email.length <= 0) {
      return false
    } else if (password.length < 0) {
      return false
    } else {
      return true
    }
  }

  const handleLoginSubmit = async (event) => {
    if (event) {
      event.preventDefault()
    }

    if (validateForm()) {
      try {
        const response = await getTokensRequest(email, password)
        if (rememberEmail) {
          window.localStorage.setItem('REMEMBERED_USER_EMAIL', email)
        } else {
          window.localStorage.removeItem('REMEMBERED_USER_EMAIL')
        }
        login(response.data)
      } catch (err) {
        if (err.response) {
          errorNotification('credentialError')
        } else {
          errorNotification('generalError')
        }
      }
    }
  }

  const handleRememberMe = () => {
    setRememberEmail(!rememberEmail)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleInsertReset = async () => {
    try {
      await sendEmailPasswordReset(resetEmail)
      successNotification('sendEmail')
    } catch (err) {
      if (err.response) {
        errorNotification('emailNotFound')
      } else {
        errorNotification('generalError')
      }
    }
    setOpen(false)
  }

  return (
    <div className={classes.loginForm}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Ingresa tu E-mail</DialogTitle>
        <DialogContent>
          <FormControl color="secondary">
            <InputLabel htmlFor="email-input">E-mail</InputLabel>
            <Input
              id="email-input"
              type="email"
              value={resetEmail}
              autoFocus
              onChange={(e) => setResetEmail(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInsertReset} color="primary">
            Listo
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleLoginSubmit}>
        <FormControl color="secondary" className={classes.InputWrapper}>
          <InputLabel htmlFor="email-input">E-mail</InputLabel>
          <Input
            id="email-input"
            type="email"
            value={email}
            autoFocus={!rememberedEmail}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl color="secondary" className={classes.InputWrapper}>
          <InputLabel htmlFor="password-input">Contraseña</InputLabel>
          <Input
            id="password-input"
            type="password"
            autoFocus={!!rememberedEmail}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>

        <div className={classes.loginHelpers}>
          <div className={classes.rememberMeCheckbox}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberEmail}
                    onChange={handleRememberMe}
                    name="remember-email"
                    color="secondary"
                  />
                }
                label="Recordarme"
              />
            </FormControl>
          </div>

          <div className={classes.forgotPasswordLink}>
            <Button variant="text" onClick={() => handleClickOpen()}>
              ¿Olvide mi contraseña?
            </Button>
          </div>
        </div>

        <Button
          className={classes.btnSubmit}
          type="submit"
          variant="contained"
          size="large"
        >
          Iniciar Sesión
        </Button>
      </form>
    </div>
  )
}
