import { useContext, useEffect, useState } from 'react'

import { format } from 'date-fns'

import HeaderContext from 'context/headerContext'
import Loading from 'utils/UI/Loading'
import PaginatedTable from 'utils/UI/Tables/PaginatedTable'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { StatementsRequest } from 'axios/requests/documents'

const colsData = [
  {
    id: 'date',
    label: 'Fecha'
  },
  {
    id: 'description',
    label: 'Descripción'
  },
  {
    id: 'url',
    label: 'Link de Descarga',
    format: (value) => {
      return (
        <a href={value} download>
          Descargar
        </a>
      )
    }
  }
]

export default function DividendsTable() {
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [loading, setLoading] = useState(false)
  const [rowsData, setRowsData] = useState()

  useEffect(() => {
    setLoading(true)
    let mounted = true
    const fetchData = async () => {
      try {
        const result = await StatementsRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency: currency,
          portfolio_id: currentPortfolio.id
        })
        return result.data
      } catch (err) {
        console.log(err)
      }
    }

    if (mounted && currentPortfolio && currency) {
      fetchData().then((data) => {
        if (mounted && data?.tableData) {
          setRowsData(data.tableData)
          setLoading(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [currency, currentPortfolio, startDate, endDate])

  return (
    <CardWrapper title="Cartolas">
      {rowsData && !loading ? (
        <PaginatedTable colsData={colsData} rowsData={rowsData} />
      ) : (
        <Loading />
      )}
    </CardWrapper>
  )
}
