import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'

export const StyledTableCell = withStyles((theme) => ({
  head: {
    border: 'transparent',
    fontFamily: 'InconsolataSemiBold',
    fontSize: 16,
    paddingRight: 5,
    paddingLeft: 5,
    paddingBottom: 4,
    borderBottom: '1px solid' + theme.palette.secondary.main,
    whiteSpace: 'nowrap',
    backgroundColor: 'transparent'
  },
  body: {
    fontFamily: 'InconsolataRegular',
    fontWeight: 400,
    fontSize: '0.875rem',
    paddingRight: 5,
    paddingLeft: 5,
    whiteSpace: 'nowrap',
    maxHeight: '32px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    animation: '$fadeIn 0.5s ease-in-out'
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  }
}))(TableCell)

export const StyledTableContainer = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    width: '100%',
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      '& *': {
        fontSize: '0.8rem'
      }
    }
  }
}))(TableContainer)
