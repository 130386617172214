import { useContext } from 'react'

import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined'
import ScheduleIcon from '@material-ui/icons/Schedule'

import SelectDate from 'components/DatePicker'
import HeaderContext from 'context/headerContext'
import CustomRadioSelector from 'utils/UI/Selectors/CustomRadioSelector'

const defaultDatePeriods = [
  { value: '1M', key: '1M', label: '1M' },
  { value: '6M', key: '6M', label: '6M' },
  { value: 'YTD', key: 'YTD', label: 'YTD' },
  { value: '1Y', key: '1Y', label: '1Y' },
  { value: 'ITD', key: 'ITD', label: 'ITD' }
]

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    display: 'flex',
    marginTop: (props) => (props.vertical ? theme.spacing(1) : 0),
    flexDirection: (props) => (props.vertical ? 'column' : 'row'),
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
    '& hr': {
      width: (props) => props.vertical && '100%',
      height: (props) => props.vertical && 1
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  filterItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 56
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 120
  },
  datePickersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column'
      '& .MuiPickersDateRangePickerInput-root': {
        flexDirection: 'row'
      },
      '& .divisor': {
        display: 'none'
      },
      '& $datePicker': {
        width: '12ch',
        padding: 0,
        alignItems: 'start',
        '&:first-child': {
          marginRight: theme.spacing(0.5)
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  datePicker: {
    border: 'none',
    width: '14ch',
    padding: theme.spacing(1)
  },
  calendar: {
    '& .react-datepicker__day--in-selecting-range': {
      background: '#fcede8'
    },
    '& .react-datepicker__day--in-range': {
      background: theme.palette.primary.main
    }
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: 8
  }
}))

export default function OptionsCard({
  selectedCurrency,
  handleCurrencyChange,
  selectedPeriod,
  handlePeriodChange,
  portfolioList,
  selectedPortfolio,
  handlePortfolioChange,
  usingPeriod,
  setUsingPeriod,
  vertical
}) {
  const classes = useStyles({ vertical })
  const dividerOrientation = vertical ? 'horizontal' : 'vertical'
  const { headerState } = useContext(HeaderContext)

  return (
    <Paper className={classes.paperContainer}>
      <div className={classes.filterItem}>
        <CustomRadioSelector
          title={null}
          currentValue={selectedCurrency}
          onChangeHandler={handleCurrencyChange}
          icon={<AttachMoneyIcon />}
          radioList={headerState.currencyList}
        />
      </div>
      <Divider orientation={dividerOrientation} flexItem />
      <div className={classes.filterItem}>
        <FormControl className={classes.formControl}>
          <div className={classes.iconContainer}>
            <FolderOutlinedIcon />
          </div>
          <Select
            disableUnderline
            IconComponent={ExpandMore}
            value={selectedPortfolio.name}
            onChange={handlePortfolioChange}
            label={selectedPortfolio.name}
          >
            {portfolioList.map((portfolio) => (
              <MenuItem key={portfolio.id} value={portfolio.name}>
                {portfolio.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Divider orientation={dividerOrientation} flexItem />
      <div className={classes.filterItem}>
        <CustomRadioSelector
          title={null}
          currentValue={usingPeriod ? selectedPeriod : null}
          onChangeHandler={handlePeriodChange}
          icon={<ScheduleIcon />}
          radioList={defaultDatePeriods}
        />
      </div>
      <Divider orientation={dividerOrientation} flexItem />
      <div className={classes.filterItem}>
        <div className={classes.datePickersContainer}>
          <Grid container>
            <Grid item xs={6}>
              <SelectDate
                dateProperty="startDate"
                setUsingPeriod={setUsingPeriod}
                changeType="STARTDATE_CHANGE"
                minDate={headerState.minDate}
                maxDate={headerState.endDate}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectDate
                dateProperty="endDate"
                setUsingPeriod={setUsingPeriod}
                changeType="ENDDATE_CHANGE"
                minDate={headerState.startDate}
                maxDate={headerState.maxDate}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Paper>
  )
}
