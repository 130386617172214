import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { format, parseISO } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import SaveIcon from '@material-ui/icons/Save'
import Autocomplete from '@material-ui/lab/Autocomplete'

import HeaderContext from 'context/headerContext'
import Loading from 'utils/UI/Loading'
import { errorNotification } from 'utils/UI/Notifications/Notifications'
import { COLORS_GRAPH_ASSETS, getTextColor } from 'utils/UI/Theme'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import {
  assetsSearchRequest,
  createSearchListRequest,
  deleteSearchListRequest,
  downloadSeriesReturnsRequest,
  instrumentSeriesReturnsRequest,
  portfolioAssetsSearchRequest,
  updateSearchListRequest
} from 'axios/requests/assetsComparator'

const useStyles = makeStyles((theme) => ({
  assetsSelectorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  assetsSelector: {},
  saveButton: {
    marginLeft: 'auto'
  },
  deleteIcon: {
    color: '#EAEAEA'
  }
}))

export default function AssetsSeriesReturns({ handleData }) {
  const classes = useStyles()
  const theme = useTheme()
  const [openSaveDialog, setOpenSaveDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [savedListEdit, setSavedListEdit] = useState({ uuid: null, text: '' })
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [loading, setLoading] = useState(true)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [tickers, setTickers] = useState([])
  const [savedList, setSavedList] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [optionsTickers, setOptionsTickers] = useState([])
  const [assetsNamesDict, setAssetsNamesDict] = useState({})
  const intervalRef = useRef()
  const [assetIdToIndex, setAssetIdToIndex] = useState({})
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [loadingSavedList, setLoadingSavedList] = useState(false)

  const [open, setOpen] = useState(false)

  const [chartOptions, setChartOptions] = useState({
    credits: false,
    plotOptions: {
      line: {
        marker: {
          enabled: false
        },
        events: {
          legendItemClick: function () {
            return false
          }
        }
      }
    },
    chart: {
      zoomType: 'x',
      style: {
        fontFamily: 'InconsolataRegular'
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          color: theme.palette.text.primary
        }
      }
    },
    yAxis: {
      title: {
        text: null
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: theme.palette.text.primary
        }
      ],
      labels: {
        style: {
          color: theme.palette.text.primary
        },
        formatter: function () {
          return ((this.value - 1) * 100).toFixed(1) + '%'
        }
      }
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
      formatter: function () {
        let points = ''
        this.points
          .sort((a, b) => {
            return b.point.y - a.point.y
          })
          .forEach((point) => {
            const shareValue = point.series.options.dataExtra[this.x]
            points += `<span style="color: ${point.series.color}">●</span> ${
              point.series.name
            }: ${((point.y - 1) * 100).toFixed(1) + '%'} ($${shareValue.toFixed(
              2
            )})<br>`
          })
        const date = new Date(this.x)
        const isoDate = date.toISOString()
        const dateOnly = isoDate.substring(0, isoDate.indexOf('T'))
        return (
          '<b style="font-size: 11px  ">' +
          format(parseISO(dateOnly), 'dd/MM/yyyy') +
          // Highcharts.dateFormat('%e - %b - %Y', new Date(this.x)) +
          '</b>' +
          '<br>' +
          '<div style="margin-top: 20px">' +
          points +
          '</div>'
        )
      }
    },
    legend: {
      enabled: false
    },
    lang: {
      noData: ''
    },
    series: [
      {
        name: '',
        color: theme.palette.primary.main,
        data: [],
        turboThreshold: 0
      }
    ]
  })

  useEffect(() => {
    let mounted = true
    if (tickers.length > 0) {
      const fetchData = async () => {
        try {
          const result = await instrumentSeriesReturnsRequest({
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            currency: currency,
            tickers: tickers.join(',')
          })
          handleData(result.data)
          return result.data
        } catch (err) {
          errorNotification('miniBloombergError')
        }
      }

      if (mounted && currentPortfolio && currency) {
        window.addEventListener('transitionend', () => {
          Highcharts.charts.forEach((chart) => {
            if (chart) {
              chart.reflow()
            }
          })
        })
        setLoading(true)

        if (!tickers.length) {
          setLoading(false)
          return
        }
        fetchData().then((result) => {
          if (mounted && result) {
            const series = []
            for (let index = 0; index < result.length; index++) {
              const asset = result[index]
              const colorIndex = result.length - 1 - index
              const dataExtra = {}
              const data = asset.series.map((element) => {
                dataExtra[Date.parse(element[0])] = element[2]
                return [Date.parse(element[0]), element[1], element[2]]
              })
              setAssetsNamesDict((prevState) => {
                return {
                  ...prevState,
                  [asset.id]: asset.name
                }
              })
              setAssetIdToIndex((prevState) => ({
                ...prevState,
                [asset.id]: colorIndex
              }))
              series.push({
                ...asset,
                color: COLORS_GRAPH_ASSETS[colorIndex],
                data,
                dataExtra
              })
            }
            setChartOptions({ ...chartOptions, series })
          }
          setLoading(false)
        })
      }
      return () => {
        mounted = false
        window.removeEventListener('transitionend', () => {
          Highcharts.charts.forEach((chart) => {
            if (chart) {
              chart.reflow()
            }
          })
        })
      }
    } else {
      setLoading(false)
      handleData(null)
    }
  }, [
    currency,
    currentPortfolio,
    startDate,
    endDate,
    theme.palette.secondary.main,
    tickers
  ])

  useEffect(() => {
    const assetSearchTickers = async () => {
      const result = await portfolioAssetsSearchRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency: currency,
        search_term: searchTerm,
        portfolio_id: currentPortfolio.id
      })
      return result.data
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(() => {
      if (searchTerm.length > 0) {
        setLoadingSearch(true)
        assetSearchTickers().then((tickersFounded) => {
          setOptionsTickers(tickersFounded)
          setLoadingSearch(false)
        })
      } else {
        setLoadingSearch(false)
        setOptionsTickers([])
      }
      clearInterval(intervalRef.current)
    }, 500)
  }, [searchTerm, currentPortfolio, startDate, endDate, currency])

  useEffect(() => {
    setLoadingSavedList(true)
    const fetchList = async () => {
      try {
        const data = await fetchSavedList()
        setSavedList(data)
      } catch (err) {
        errorNotification('miniBloombergError')
      } finally {
        setLoadingSavedList(false)
      }
    }

    fetchList()
  }, [])

  const handleOnInputChange = async (e) => {
    setSearchTerm(e.target.value)
  }

  const fetchSavedList = async () => {
    try {
      const result = await assetsSearchRequest()
      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const deleteSavedData = async (uuid) => {
    try {
      const result = await deleteSearchListRequest({
        uuid
      })
      fetchSavedList().then((data) => {
        setSavedList(data)
        setSavedListEdit({ uuid: null, text: '' })
      })
      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const upsertSavedData = async (params) => {
    try {
      let result
      if (params.uuid) {
        result = await updateSearchListRequest({
          ...params
        })
      } else {
        result = await createSearchListRequest({
          ...params
        })
      }
      fetchSavedList().then((data) => {
        setSavedList(data)
        if (params.uuid) {
          setSavedListEdit({ uuid: null, text: '' })
          if (params.replaceList) {
            cleanSavedData()
          }
        } else {
          cleanSavedData()
        }
      })
      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const cleanSavedData = async () => {
    setOpenEditDialog(false)
    setOpenSaveDialog(false)
    setSavedListEdit({ uuid: null, text: '' })
  }

  const optionsTickersDict = useMemo(() => {
    return optionsTickers.reduce((acc, ticker) => {
      acc[ticker.ticker] = ticker.name
      return acc
    }, {})
  }, [optionsTickers])

  const optionsTickersIds = useMemo(() => {
    return [...tickers, ...optionsTickers.map((ticker) => ticker.ticker)]
  }, [tickers, optionsTickers])

  const downloadHandler = async () => {
    setLoadingDownload(true)
    try {
      const response = await downloadSeriesReturnsRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency: currency,
        tickers: tickers.join(',')
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Abaqus - Series returns.xlsx') // or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingDownload(false)
  }

  return (
    <CardWrapper
      title="Evolución rentabilidad de activos"
      titleFeedback="Evolución rentabilidad de activos"
    >
      <Grid
        container
        className={classes.assetsSelectorContainer}
        alignItems="center"
      >
        <Grid item xs={10}>
          <Autocomplete
            loading={loadingSearch}
            open={open}
            onOpen={() => {
              setOpen(true)
            }}
            onClose={() => {
              setOpen(false)
            }}
            multiple
            getOptionLabel={(option) => {
              return `${optionsTickersDict[option]} (${option})`
            }}
            filterSelectedOptions
            getOptionSelected={(option, value) => value === option}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => {
                if (assetIdToIndex[option] !== undefined) {
                  return (
                    <Tooltip title={assetsNamesDict[option]} key={index}>
                      <Chip
                        style={{
                          marginRight: 12,
                          backgroundColor:
                            COLORS_GRAPH_ASSETS[assetIdToIndex[option]],
                          color: getTextColor(
                            COLORS_GRAPH_ASSETS[assetIdToIndex[option]]
                          )
                        }}
                        size="small"
                        label={option}
                        key={option}
                        classes={{ deleteIcon: classes.deleteIcon }}
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  )
                } else {
                  return null
                }
              })
            }}
            loadingText={
              loadingSearch ? 'Buscando...' : 'No se encontraron resultados'
            }
            onBlur={() => setOptionsTickers([])}
            options={loadingSearch ? [] : optionsTickersIds}
            onInputChange={(e) => handleOnInputChange(e)}
            value={tickers}
            size="small"
            onChange={(_, _tickers) => {
              setTickers(_tickers)
            }}
            className={classes.assetsSelector}
            noOptionsText="No se encontraron resultados"
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Selecciona uno o más activos:"
                  variant="outlined"
                  placeholder="Activos"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={tickers.length ? 'Guardar' : ''}>
            <IconButton
              disabled={!tickers.length}
              onClick={() => setOpenSaveDialog(true)}
              color="primary"
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={tickers.length ? 'Descargar' : ''}>
            {loadingDownload ? (
              <CircularProgress />
            ) : (
              <IconButton
                disabled={!tickers.length}
                onClick={downloadHandler}
                color="primary"
              >
                <GetAppIcon />
              </IconButton>
            )}
          </Tooltip>
        </Grid>
      </Grid>
      {loading ? (
        <div style={{ height: 400 }}>
          <Loading />
        </div>
      ) : tickers.length > 0 ? (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      ) : (
        <Grid container style={{ height: 200 }} spacing={1}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
            spacing={3}
          >
            <Typography variant="h1" component="h2">
              Por favor selecciona los activos que quieres comparar
            </Typography>
          </Grid>
        </Grid>
      )}
      {loadingSavedList ? (
        <Loading />
      ) : (
        savedList?.length > 0 && (
          <Grid container alignItems="center">
            {savedList?.map((savedListItem) => (
              <Chip
                key={savedListItem.uuid}
                style={{
                  marginRight: 12,
                  color: '#fff'
                }}
                size="small"
                label={savedListItem.name}
                color="primary"
                onClick={() => setTickers(savedListItem.tickers.split(','))}
              />
            ))}
            <Tooltip title="Editar">
              <IconButton
                onClick={() => setOpenEditDialog(true)}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openSaveDialog}
        onClose={() => setOpenSaveDialog(false)}
      >
        <DialogTitle>Guardar lista de activos</DialogTitle>
        <DialogContent>
          <List>
            {savedList?.map((savedListItem) => (
              <ListItem key={savedListItem.uuid}>
                <ListItemText primary={savedListItem.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="save"
                    onClick={() =>
                      upsertSavedData({
                        uuid: savedListItem.uuid,
                        name: savedListItem.name,
                        tickers: tickers.join(','),
                        replaceList: true
                      })
                    }
                  >
                    <SaveIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <TextField
                    label="Nueva lista"
                    size="small"
                    value={savedListEdit.text}
                    onChange={(e) =>
                      setSavedListEdit({ uuid: null, text: e.target.value })
                    }
                    placeholder="Nueva lista"
                    margin="normal"
                    fullWidth
                  />
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="save"
                  onClick={() => {
                    upsertSavedData({
                      name: savedListEdit.text,
                      tickers: tickers.join(',')
                    })
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      >
        <DialogTitle>Editar listas guardadas</DialogTitle>
        <DialogContent>
          <List>
            {savedList?.map((savedListItem) => (
              <ListItem key={savedListItem.uuid}>
                {savedListEdit.uuid === savedListItem.uuid ? (
                  <TextField
                    value={savedListEdit.text}
                    onChange={(e) =>
                      setSavedListEdit({
                        uuid: savedListItem.uuid,
                        text: e.target.value
                      })
                    }
                  />
                ) : (
                  <ListItemText primary={savedListItem.name} />
                )}
                <ListItemSecondaryAction>
                  {savedListEdit.text &&
                  savedListItem.uuid === savedListEdit.uuid ? (
                    <IconButton
                      edge="end"
                      onClick={() =>
                        upsertSavedData({
                          uuid: savedListItem.uuid,
                          name: savedListEdit.text,
                          tickers: savedListItem.tickers
                        })
                      }
                    >
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      edge="end"
                      onClick={() =>
                        setSavedListEdit({
                          uuid: savedListItem.uuid,
                          text: savedListItem.name
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    aria-label="save"
                    onClick={() => deleteSavedData(savedListItem.uuid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </CardWrapper>
  )
}
