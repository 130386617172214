import { useContext, useEffect, useState } from 'react'

import { format } from 'date-fns'

import { useTheme } from '@material-ui/core/styles'
import Highcharts from 'highcharts'

import BaseGraph from 'components/Graphs/BaseGraph'
import HeaderContext from 'context/headerContext'
import Loading from 'utils/UI/Loading'

import { performanceSeriesRequest } from 'axios/requests/summary'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

export default function PerformanceSeries({ balanceData }) {
  const theme = useTheme()
  const { headerState } = useContext(HeaderContext)
  const {
    startDate,
    endDate,
    currency,
    currentPortfolio,
    portfolios,
    portfoliosDict
  } = headerState
  const [loading, setLoading] = useState(true)
  const [mode, setMode] = useState('valor-cuota')
  const [comparators, setComparators] = useState([])
  const [portfolioData, setPortfolioData] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        Highcharts.charts.forEach((chart) => {
          if (chart) chart.showLoading()
        })
        if (currentPortfolio) {
          const result = await performanceSeriesRequest({
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            currency: currency,
            portfolio_id: currentPortfolio.id,
            mode: mode,
            comparator:
              mode === 'valor-cuota' && comparators.length
                ? comparators.join(',')
                : ''
          })
          return result.data
        } else {
          const result = await performanceSeriesRequest({
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            currency: currency,
            portfolio_id: balanceData.portfolio.id,
            mode: mode,
            comparator:
              mode === 'valor-cuota' && comparators.length
                ? comparators.join(',')
                : ''
          })
          return result.data
        }
      } catch (err) {
        console.log(err)
      }
    }

    setLoading(true)
    fetchData().then((data) => {
      setPortfolioData(data)
    })
    setLoading(false)
  }, [
    currency,
    currentPortfolio,
    comparators,
    mode,
    startDate,
    endDate,
    theme.palette.secondary.main,
    portfoliosDict,
    balanceData
  ])
  return (
    <>
      <CardWrapper title="Evolucion" shadow={false}>
        {loading ? (
          <Loading />
        ) : (
          <BaseGraph
            balanceData={balanceData}
            seriesData={portfolioData}
            portfolio={currentPortfolio}
            portfolios={portfolios}
            portfoliosDict={portfoliosDict}
            comparators={comparators}
            setComparators={setComparators}
            mode={mode}
            setMode={setMode}
            currency={currency}
          />
        )}
      </CardWrapper>
    </>
  )
}
