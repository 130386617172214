import { useMemo, useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

import { StyledTableCell, StyledTableContainer } from 'assets/jss/Tables/Tables'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

const StyledTablePagination = withStyles(() => ({
  root: {
    border: 'transparent'
  },
  toolbar: {
    margin: 0,
    padding: 0
  }
}))(TablePagination)

const useStylesPagination = makeStyles(() => ({
  root: {
    padding: 0,
    flexShrink: 0,
    width: '100%',
    marginRight: 'auto'
  },
  tableFooter: {
    position: 'fixed'
  }
}))

function TablePaginationActions(props) {
  const classes = useStylesPagination()
  const theme = useTheme()
  const { page, onPageChange, numpage } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 1)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, numpage)
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 1}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      {page} de {numpage}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= numpage}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= numpage}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  hideSortIcon: {
    '& .MuiTableSortLabel-icon': {
      display: 'none',
      visibility: 'hidden'
    }
  }
})

export default function PaginatedTable({
  colsData,
  rowsData,
  page,
  numpage,
  handleChangePage
}) {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(null)
  const [hideSortIcon] = useState(true)
  const maxWidth = 40

  const data = useMemo(() => {
    if (!orderBy) return rowsData
    const sortedData = rowsData.sort((a, b) => {
      if (isNaN(a[orderBy])) {
        return order === 'asc'
          ? a[orderBy].localeCompare(b[orderBy])
          : b[orderBy].localeCompare(a[orderBy])
      } else {
        return order === 'asc'
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy]
      }
    })
    return sortedData
  }, [order, orderBy, rowsData])

  return (
    <Auxiliary>
      <StyledTableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {colsData.map((column, index) => (
                <StyledTableCell
                  key={column.id.toString() + index.toString()}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sortDirection={
                    orderBy === column.id && column.sortable !== false
                      ? order
                      : false
                  }
                >
                  {column.sortable !== false ? ( // Check if column is not explicitly set to non-sortable
                    <TableSortLabel
                      className={
                        hideSortIcon && orderBy !== column.id
                          ? classes.hideSortIcon
                          : ''
                      }
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => {
                        const isAsc = orderBy === column.id && order === 'asc'
                        setOrder(isAsc ? 'desc' : 'asc')
                        setOrderBy(column.id)
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                {colsData.map((column) => {
                  const value = row[column.id]
                  let displayValue = column.format
                    ? column.format(value)
                    : value

                  if (displayValue && displayValue.length > maxWidth) {
                    displayValue = displayValue.substring(0, maxWidth) + '...'
                    return (
                      <StyledTableCell
                        key={column.id.toString() + index.toString()}
                        align={column.align}
                      >
                        <Tooltip title={value}>
                          <span>{displayValue}</span>
                        </Tooltip>
                      </StyledTableCell>
                    )
                  } else {
                    return (
                      <StyledTableCell
                        key={column.id.toString() + index.toString()}
                        align={column.align}
                      >
                        {displayValue}
                      </StyledTableCell>
                    )
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter className={classes.tableFooter}>
            <TableRow>
              <StyledTablePagination
                rowsPerPageOptions={[15]}
                count={-1}
                rowsPerPage={-1}
                page={page}
                numpage={numpage}
                onPageChange={handleChangePage}
                labelDisplayedRows={() => ''}
                ActionsComponent={(props) =>
                  TablePaginationActions({ ...props, numpage })
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </StyledTableContainer>
    </Auxiliary>
  )
}
