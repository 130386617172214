import { Redirect, BrowserRouter as Router, Switch } from 'react-router-dom'

import ChangePasswordPage from 'components/ChangePasswordPage/ChangePasswordPage'
import ConfirmationPage from 'components/ConfirmationPage/ConfirmationPage'
import Header from 'components/DashboardPage/Header/Header'
import LoginPage from 'components/LoginPage/LoginPage'
import NotFoundPage from 'components/NotFoundPage/NotFoundPage'
import AuthContext from 'context/authContext'
import { useAuth } from 'utils/Auth/AuthProvider'
import LoginRoute from 'utils/Routing/LoginRoute'
import PrivateRoute from 'utils/Routing/PrivateRoute'
import PublicRoute from 'utils/Routing/PublicRoute'

export default function AppRouter() {
  const { isUserAuthenticated, userDetails } = useAuth()

  return (
    <AuthContext.Provider value={{ isUserAuthenticated, userDetails }}>
      <Router>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <LoginRoute path="/login" component={LoginPage} />
          <PrivateRoute path="/dashboard" component={Header} />
          <PublicRoute
            path="/confirmation/:token"
            exact
            component={ConfirmationPage}
          />
          <PublicRoute
            path="/reset-password"
            exact
            component={ChangePasswordPage}
          />

          <PrivateRoute
            path="/change-password"
            component={ChangePasswordPage}
          />
          <PublicRoute path="/not-found" component={NotFoundPage} />
          <Redirect to="/not-found" />
        </Switch>
      </Router>
    </AuthContext.Provider>
  )
}
