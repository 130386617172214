import AssetBenchmarkSeries from 'components/Graphs/AssetBenchmarkSeries'
import TreeContributionGraph from 'components/Graphs/TreeContribution'
import TreeReturnsGraph from 'components/Graphs/TreeReturns'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

export default function Analysis() {
  return (
    <Auxiliary>
      <TreeContributionGraph />
      <TreeReturnsGraph />
      <AssetBenchmarkSeries />
    </Auxiliary>
  )
}
