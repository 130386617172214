import { IconButton } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import GetAppIcon from '@material-ui/icons/GetApp'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(),
    backgroundColor: 'white',
    marginLeft: 0,
    marginRight: 0
  }
}))

export default function DownloadButton({ onClickHandler, small, onlyIcon }) {
  const classes = useStyles()

  return (
    <Auxiliary>
      {!onlyIcon ? (
        <Button
          variant="contained"
          size={small ? 'small' : 'medium'}
          className={classes.button}
          onClick={onClickHandler}
          startIcon={<GetAppIcon />}
        >
          Descargar
        </Button>
      ) : (
        <IconButton onClick={onClickHandler}>
          <GetAppIcon />
        </IconButton>
      )}
    </Auxiliary>
  )
}
