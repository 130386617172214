import { Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { errorNotification } from 'utils/UI/Notifications/Notifications'

import useResponsive from 'hooks/useResponsive'
import DownloadButton from 'utils/UI/Buttons/DownloadButton'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { AllocationsReportRequest } from 'axios/requests/operations'

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      '& p': {
        marginRight: 20
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& p': {
        marginRight: 0
      }
    }
  },
  reportContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2)
  },
  reportCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
    width: '100%'
  },
  cardReportName: {
    fontSize: 20,
    marginRight: 'auto'
  }
}))

export default function OPReports() {
  const classes = useStyles()
  const { isMobile } = useResponsive()
  const [loadingAllocations, setLoadingAllocations] = useState(false)

  const downloadAllocationsHandler = async () => {
    setLoadingAllocations(true)
    try {
      const response = await AllocationsReportRequest({
        aggregate: 'root-class'
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Abaqus - Allocations Report' + '.xlsx') // or any other extension
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingAllocations(false)
  }

  return (
    <div className={classes.contentContainer}>
      <div className={classes.reportContainer}>
        <CardWrapper className={classes.reportCard} width="100%">
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems="center"
          >
            <Typography className={classes.cardReportName}>
              Reporte Allocations
            </Typography>
            {loadingAllocations ? (
              <CircularProgress />
            ) : (
              <DownloadButton onClickHandler={downloadAllocationsHandler} />
            )}
          </Grid>
        </CardWrapper>
      </div>
    </div>
  )
}
